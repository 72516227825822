html, body{width: 100%; line-height: normal; -webkit-font-smoothing: antialiased; font-family: 'Figtree', sans-serif !important;font-weight: 400; letter-spacing: 0.6px; color: #1a1a1a;position: relative;overflow-x: hidden;scroll-behavior: smooth;}
* {
	box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {font-weight: normal;line-height: normal;}
dl,
dt,
dd,
ol,
ul,
li {margin: 0;padding: 0;list-style-type: none;}
a:active,
select,
select:focus,
a:focus,
a:hover,
input:focus,
button:focus,
textarea:focus {outline: none !important;text-decoration: none;-webkit-transition: all 0.5s ease 0s;-moz-transition: all 0.5s ease 0s;transition: all 0.5s ease 0s;}
img {max-width: 100%;height: auto;}
.form-select:focus {border-color: transparent;outline: 0;box-shadow: none;}
a {text-decoration: none; -webkit-transition: all 0.5s ease 0s;-moz-transition: all 0.5s ease 0s;transition: all 0.5s ease 0s;}
a:hover{text-decoration: none !important;color: #FF7700;}
label {font-weight: normal;}
input-placeholder {color: #969696;}
:-moz-placeholder {color: #969696;}
:-o-placeholder {color: #969696;}
#header .navbar-nav .dropdown-item.active, #header .navbar-nav .dropdown-item:active{background-color: var(--bs-dropdown-link-hover-bg); color: var(--bs-dropdown-link-hover-color);}

.table tr td, .table tr th{vertical-align: middle;}

/*--------------------------------------------*/
/*Header Section*/
/*--------------------------------------------*/
#header{display: block; position: fixed;top: 0px;left: 0;right: 0;z-index: 999; padding: 20px 15px 10px 15px; -webkit-transition: all 0.5s ease 0s;-moz-transition: all 0.5s ease 0s;transition: all 0.5s ease 0s;background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );	}
#header.sticky-header{background-color: #fff;padding-top: 10px;}
#header .navbar-expand-xxl .navbar-nav .nav-link {list-style-type: none;margin: 0 10px;line-height: 40px; text-decoration: none;display: block;font-size: 15px; text-transform: uppercase; color: #144778; font-weight: 600;padding: 0;display: table-cell;}
#header .navbar-expand-xxl .navbar-nav .nav-link:hover{color: #f18221;}
#header .navbar-expand-xxl .navbar-nav .dropdown{margin: 0 10px;}
#header .navbar-expand-xxl .navbar-nav .nav-link .form-select{width: 52px; height: 40px;border: 0; background-color: #fff;border-radius: 10px;box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);color: #144778;font-size: 16px; text-transform: uppercase; color: #144778; font-weight: 600;padding: 0 10px;background-position: right 0.2rem center;}
#header .navbar-nav .dropdown-menu{box-shadow: 0 7px 20px rgba(0, 0, 0, 0.2);border-radius: 10px;border: 0;}
#header .navbar-nav .dropdown-menu .dropdown-item{line-height: 30px;font-size: 14px;text-transform: uppercase;}
#header .navbar-nav .dropdown-menu .dropdown-item:hover{color: #f18221;}
#header .navbar-nav .dropdown-menu .nav-item{margin: 0;}
#header .navbar-nav .dropdown-menu .nav-item .nav-link{color: var(--bs-dropdown-link-color);font-size: 14px;font-weight: 400;padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);width: 100%;margin: 0;display: block;}


/*--------------------------------------------*/
/*Home - Banner Section*/
/*--------------------------------------------*/
.home-slider .swiper-slide{position: relative;height: 900px;padding-top: 150px;}
.home-slider .swiper-slide .slider-bg{width: 100%; height: 100%;position: absolute;top: 0; left: 0;right: 0;object-fit: cover;z-index: -1;}
.home-slider .swiper-slide.slider-1 h1{font-size: 70px; font-weight: 800; line-height: 80px; text-transform: uppercase;color: #144778;margin-bottom: 30px;margin-top: 150px;}
.home-slider .swiper-slide.slider-1 h1 span{color: #f18221;display: block;}
.home-slider .swiper-slide.slider-1 h6{font-size: 20px; line-height: 28px;margin-bottom: 40px;}
.home-slider .swiper-slide.slider-1 h4{font-size: 24px; line-height: 28px;font-weight: 800; font-style: italic;}
.home-slider .swiper-slide.slider-1 .slider-hero{width: 90%;}
.home-slider .swiper-button-next, .home-slider .swiper-button-prev{height: 60px; width: 60px;background-color: rgba(20, 71, 120, 0.1);border-radius: 100%;}
.home-slider .swiper-button-next::after, .home-slider .swiper-rtl .swiper-button-prev::after{font-size: 20px;color: #144778;}
.home-slider .swiper-button-prev::after, .home-slider .swiper-rtl .swiper-button-next::after{font-size: 20px;color: #144778;}

.home-slider .swiper-slide.slider-2 h1{font-size: 100px; font-weight: 900; line-height: 90px; text-transform: uppercase;margin-bottom: 30px;margin-top: 30%;color: transparent !important; -webkit-text-stroke-width: 2px; -webkit-text-stroke-color: #fff; font-family: sans-serif;letter-spacing: 0;}
.home-slider .swiper-slide.slider-2 h6{font-size: 20px; line-height: 28px;color: #fff; margin-bottom: 40px;}
.home-slider .swiper-slide.slider-2 h4{font-size: 24px; line-height: 28px;font-weight: 800; font-style: italic; color: #fff;}

.home-slider .swiper-slide.slider-3 h1{font-size: 64px; font-weight: 800; line-height: 70px; text-transform: uppercase;margin-bottom: 30px;margin-top: 30%;color: #26252b;}
.home-slider .swiper-slide.slider-3 h6{font-size: 22px; font-weight: 500; line-height: 28px;}
.home-slider .swiper-slide.slider-3 h4{font-size: 24px; line-height: 28px;font-weight: 800; font-style: italic; margin-top: 50px;}

.home-slider .swiper-slide.slider-4 h1{font-size: 64px; font-weight: 900; line-height: 70px; text-transform: uppercase;margin-bottom: 30px;margin-top: 15%;color: #fff;text-align: center;}
.home-slider .swiper-slide.slider-4 h6{font-size: 20px; font-weight: 500; line-height: 28px;text-align: center; color: #fff;}
.home-slider .swiper-slide.slider-4 h4{font-size: 24px; line-height: 28px;font-weight: 800; font-style: italic; margin-top: 40px; color: #fff;text-align: center;}

/*--------------------------------------------*/
/*About Us*/
/*--------------------------------------------*/
#about_us{padding: 100px 0;} 
#about_us img{width: 100%;}
#about_us .content-panel h2{font-size: 44px; font-weight: 700;color: #144778; line-height: 50px;margin-bottom: 30px;margin-top: 70px;}
#about_us .content-panel p{color: #505050;font-size: 17px; line-height: 30px;}
#about_us .content-panel .btn-readmore{font-size: 16px; font-weight: 600; color: #f18221;text-align: center;line-height: 50px;margin-top: 30px;display: inline-block;}
#about_us .content-panel .btn-readmore i{height: 50px; width: 50px;border-radius: 100%; background-color: #fce6d3;display: inline-block;margin-right: 10px;color: #010101;font-size: 18px;}
#about_us .content-panel .btn-readmore:hover i{background-color: #f18221;}
#about_us h5{font-size: 17px; font-weight: 500; color: #fff; background-color: #39c83b; padding: 20px 15px;display: inline-block;text-align: center; max-width: 130px; width: 100%; position: absolute; bottom: 50px; left: 12px;}
#about_us h5 strong{font-size: 44px; font-weight: 800;display: block;}

/*--------------------------------------------*/
/*Brands*/
/*--------------------------------------------*/
#brands{padding-bottom: 100px;}
#brands h4{font-size: 18px;font-weight: 600;text-transform: uppercase;text-align: center; margin-bottom: 50px;}
#brands .brands-slider .swiper-slide{line-height: 80px;text-align: center;}
#brands .brands-slider .swiper-slide img{filter: grayscale(100%);opacity: 0.6;}
#brands .brands-slider .swiper-slide img:hover{filter: none;opacity: 1;}


/*--------------------------------------------*/
/*Testimonials*/
/*--------------------------------------------*/
#testimonials{background-color: #083e71; padding: 70px 0;}
#testimonials h6{font-size: 17px; text-transform: uppercase;color: #fff; letter-spacing: 0.6px;margin-bottom: 30px;}
#testimonials h2{font-size: 44px; font-weight: 700;color: #fff;margin-bottom: 40px;}

#testimonials .comment-box{border-radius: 10px;background-color: #144778;box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);padding: 30px;}
#testimonials .comment-box img{margin-bottom: 30px;}
#testimonials .comment-box p{color: #fff;font-size: 19px; font-weight: 400; line-height: 30px;margin-bottom: 25px;}
#testimonials .comment-box h5{font-size: 18px; font-weight: 700; color: #fff;}
#testimonials .comment-box small{font-size: 15px; color: #fff;}
#testimonials .comment-box .review-star{float: right;}
#testimonials .comment-box .review-star i{color: #f18221;}
#testimonials .swiper-pagination .swiper-pagination-bullet{background-color: #fff; opacity: 0.4;height: 10px;width: 10px;}
#testimonials .swiper-pagination .swiper-pagination-bullet-active{opacity: 1;}
#testimonials .testimonial{padding-bottom: 50px;}

/*--------------------------------------------*/
/*Our News & Updates*/
/*--------------------------------------------*/
#news-updates{padding: 70px 0;}
#news-updates h2{font-size: 44px;font-weight: 700; color: #033a6f; line-height: 50px;}
#news-updates .btn-readmore{font-size: 16px; font-weight: 600; color: #f18221;text-align: center;line-height: 50px;margin-top: 20px;display: inline-block;position: relative;z-index: 2;}
#news-updates .btn-readmore i{height: 50px; width: 50px;border-radius: 100%; background-color: #fce6d3;display: inline-block;margin-right: 10px;color: #010101;font-size: 18px;}
#news-updates .btn-readmore:hover i{background-color: #f18221;}
#news-updates p {color: #515151;font-size: 17px; line-height: 30px;}
#news-updates .news-updates{padding-top: 80px;margin-top: -50px;}
#news-updates .news-updates .swiper-slide .card{border: 0;}
#news-updates .news-updates .swiper-slide .card .card-img-top{border-radius: 10px; object-fit: cover; height: 420px;}
#news-updates .news-updates .swiper-slide .card .card-body{padding: 30px 0;}
#news-updates .news-updates .swiper-slide .card .card-body .card-text{font-size: 14px; color: #6a6a6a; line-height: normal;}
#news-updates .news-updates .swiper-slide .card .card-body .card-title{font-size: 18px; line-height: 26px; margin-bottom: 30px;}
#news-updates .news-updates .swiper-slide .card .card-body .btn-link{font-size: 15px; color: #000;text-decoration: none;text-transform: uppercase;}
#news-updates .news-updates .swiper-slide .card .card-body .btn-link:hover{color: #f18221; }
#news-updates .news-updates .swiper-button-next, #news-updates .news-updates .swiper-button-prev{top: 23px; right: 0; left: auto;background-color: #f18221; height: 50px; width: 50px;border-radius: 100%;}
#news-updates .news-updates .swiper-button-prev{right: 60px;}
#news-updates .news-updates .swiper-button-prev::after, #news-updates .news-updates .swiper-rtl .swiper-button-next::after {font-size: 18px;color: #fff;}
#news-updates .news-updates .swiper-button-next::after, #news-updates .news-updates .swiper-rtl .swiper-button-prev::after {font-size: 18px;color: #fff;}

#news-updates .news-updates .swiper-button-next.swiper-button-disabled, #news-updates .news-updates .swiper-button-prev.swiper-button-disabled{background-color: #f0f0f0 !important;opacity: 1;}
#news-updates .news-updates .swiper-button-prev.swiper-button-disabled::after, #news-updates .news-updates .swiper-rtl .swiper-button-next.swiper-button-disabled::after{color: #000 !important;}
#news-updates .news-updates .swiper-button-next.swiper-button-disabled::after, #news-updates .news-updates .swiper-rtl .swiper-button-prev.swiper-button-disabled::after {color: #000 !important;}

/*--------------------------------------------*/
/*Footer*/
/*--------------------------------------------*/
#footer{background-color: #121212;padding-top: 70px;}
#footer .content-panel{margin-bottom: 30px;}
#footer .content-panel h5{color: #fff;font-size: 22px; font-weight: 600;margin-bottom: 10px;}
#footer .content-panel p{font-size: 16px; color: #8a8a8a; line-height: 26px;}
#footer .content-panel .footer-logo{margin-bottom: 30px;}
#footer .content-panel h4{color: #fff;margin-bottom: 20px;font-size: 24px;}
#footer .content-panel .phone{color: #fff;margin-bottom: 0;}
#footer .content-panel .phone a{color: #fff;}
#footer .content-panel .email{color: #fff;}
#footer .content-panel .email a{color: #fff;}
#footer .content-panel .social-icon a{height: 40px; width: 40px;border-radius: 100%; text-align: center; line-height: 40px; display: inline-block; background-color: rgba(255, 255, 255, 0.1);margin-right: 10px;}
#footer .content-panel .social-icon a i{color: #fff;}
#footer .content-panel .social-icon a:hover{background-color: #f18221;}
#footer .copy-right{color: #acacac;font-size: 16px; text-align: center; border-top: 1px solid rgba(255, 255, 255, 0.1); padding: 20px 0;margin-top: 30px;margin-bottom: 0;}
#footer .copy-right span{color: #fff;}

/*--------------------------------------------*/
/*Product Services - Page*/
/*--------------------------------------------*/
#Product{position: relative;}
#Product img{height: 600px; object-fit: cover; width: 100%;}
#Product h2{font-size: 50px;position: absolute; top: 45%;font-weight: 700; color: #fff;}
#product_services{padding: 100px 0;} 
#product_services img{width: 100%;}
#product_services .content-panel h3{font-size: 44px; font-weight: 700;color: #144778; line-height: 50px;margin-bottom: 30px;margin-top: 30px;}
#product_services .content-panel p{color: #505050;font-size: 17px; line-height: 30px;}
#product_services.bg-light{background-color: #f7f7f7 !important;}

/*--------------------------------------------*/
/*QUALITY ASSURANCE Section*/
/*--------------------------------------------*/
#quality_assurance{padding: 70px 0;}
#quality_assurance .content-panel{margin-bottom: 30px;text-align: center;}
#quality_assurance .content-panel h2{font-size: 44px;font-weight: 700;line-height: 50px;margin-bottom: 20px;margin-top: 0px;}
#quality_assurance .content-panel p{font-size: 17px;line-height: 30px;}

/*--------------------------------------------*/
/*Stock Information*/
/*--------------------------------------------*/
#investor_info{position: relative;}
#investor_info img{height: 500px; object-fit: cover; width: 100%;}
#investor_info .content-panel{position: absolute; top: 45%;}
#investor_info .content-panel h6{font-size: 16px; text-transform: uppercase; color: #083e71;font-weight: 700;}
#investor_info .content-panel h2{font-size: 50px;font-weight: 700; color: #000;}

#stock-widget{padding: 70px 0;}
#tradingview_90856-wrapper{width: 100% !important;}

/*--------------------------------------------*/
/*Financial Summary*/
/*--------------------------------------------*/
#financial_summary{padding: 70px 0;}
#financial_summary .table-responsive .table tr td .btn{font-weight: 500;font-size: 14px;background-color: #39c83b;border: 0;color: #fff;}

/*--------------------------------------------*/
/*Prospektus*/
/*--------------------------------------------*/
#prospektus{padding: 70px 0;}
#prospektus .prospektus-card{text-align: center;padding: 30px;border-radius: 15px; border: 1px solid #dadada; }
#prospektus .prospektus-card h4{font-size: 20px;margin-top: 15px;margin-bottom: 20px;}
#prospektus .prospektus-card .btn {background-color: #39c83b;border: 0;color: #fff;font-size: 14px;font-weight: 500;text-transform: uppercase;width: 100%; height: 40px;}

/*--------------------------------------------*/
/*Announcement*/
/*--------------------------------------------*/
#announcement{padding: 70px 0;}
#announcement .table-responsive .table tr td .btn{font-weight: 500;font-size: 14px;background-color: #39c83b;border: 0;color: #fff;}

.no-data-found{text-align: center;}
.no-data-found img{height: 200px;}
.no-data-found h3{font-size: 30px; font-weight: 700;margin-top: 50px;}
.no-data-found p{font-size: 18px;color: #505050; font-weight: 500;}

/*--------------------------------------------*/
/*Our Services*/
/*--------------------------------------------*/
#services-list{padding: 70px 0; background-color: #f4f4f4;}
#services-list .content-panel h3{font-size: 44px;font-weight: 700;color: #144778;line-height: 50px;margin-bottom: 30px;margin-top: 30px;}
#services-list .list-group{border-radius: 0; background-color: transparent;}
#services-list .list-group .list-group-item{background-color: transparent;border: 0; padding: 0; line-height: 30px;font-size: 18px;margin-bottom: 10px;}
#services-list .list-group .list-group-item i{color: #f18221; margin-right: 5px;}

/* Service Features */
#service_features{padding: 100px 0;}
#service_features .feature-panel{text-align: center;margin-bottom: 30px;}
#service_features .feature-panel img{height: 80px;}
#service_features .feature-panel h4{font-size: 20px;font-weight: 600;margin-top: 20px;}
#service_features .feature-panel p{font-size: 16px;line-height: 26px;}

#investor_relations{padding: 70px 0;}
#investor_relations .table-responsive .table tr td, #investor_relations .table-responsive .table tr th{font-weight: 500;font-size: 14px;border: 0; line-height: 30px;}
#investor_relations .table-responsive .table tr th i{color: #FFAA17; margin-right: 5px;}

/*--------------------------------------------*/
/*About Us*/
/*--------------------------------------------*/
#about-us{position: relative;}
#about-us .content-panel{position: absolute; top: 45%;}
#about-us .content-panel h6{font-size: 16px; text-transform: uppercase; color: #FF7700;font-weight: 700;}
#about-us .content-panel h2{font-size: 50px;font-weight: 700; color: #144778;}
#about-us img{height: 550px;object-fit: cover;width: 100%;}

#who-we-are{padding-top: 180px; padding-bottom: 50px; position: relative;}
#who-we-are h6{color: #FF7700;font-size: 22px; font-weight: 600;margin-bottom: 20px;margin-top: 30px;}
#who-we-are h3{font-size: 34px; color: #003751; font-weight: 700;margin-bottom: 20px;}
#who-we-are p{font-size: 17px; color: #515151; line-height: 30px;}
#who-we-are .light-lamp{position: absolute; right: 30px; top: 0; height: 350px; width: auto;}
#who-we-are video {width: 100%; height: 100%;cursor: pointer;}

#quote{background-repeat: no-repeat;background-position: top center;background-size: cover;background-color: #254360; padding: 70px 0;}
#quote h4{font-size: 24px; color: #fff; font-weight: 600;text-align: center;}

/* History Timeline */
#history-timeline{padding: 70px 30px;}
#history-timeline h2{color: #003751;font-size: 34px;font-weight: 700;letter-spacing: 0px;text-align: center;}
#history-timeline .timeline {display: flex;margin: auto;justify-content: center; }
#history-timeline .timeline .circle {width: 50px;height: 50px;border-radius: 30px;background-color: #003751;margin: 0;margin-left: -5px;z-index: 2;position: relative; }
#history-timeline .timeline .circle:hover {cursor: pointer; }
#history-timeline .timeline .circle p {text-align: center;margin: auto;line-height: 50px;color: #ffffff; font-size: 14px;}
#history-timeline .timeline .link {z-index: 0;background-color: #e6e6e6;padding: 0; }
#history-timeline .square {max-width: 290px;height: 130px;border-radius: 10px;z-index: 2;position: relative; background-color: #ffffff;box-shadow: 0 0px 30px rgba(0, 0, 0, 0.2);}
#history-timeline .square::after {content: " ";	display: block;	z-index: 0;	width: 0px;	height: 0px;}
#history-timeline .square.blank {visibility: hidden; }
#history-timeline .square h3, #history-timeline .square h4 {margin: 0px 0px 0px 15px;text-align: left;font-size: 16px; }
#history-timeline .square h3 {padding-top: 5px; }
#history-timeline .square h4 {font-style: italic;font-weight: normal; }
#history-timeline .horizontal {flex-direction: row;min-width: 100%; }
#history-timeline .horizontal .link {width: auto;height: 5px;margin-top: -23px;margin-left: 5px; }
#history-timeline .horizontal .square {margin: 50px 0 40px -30px; padding: 10px;}
#history-timeline .horizontal .square p {padding: 0;margin: 0;width: 100%; font-size: 14px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 6; line-clamp: 6;-webkit-box-orient: vertical;}
#history-timeline .horizontal .square.up{border-top: 2px solid #FF7700;}
#history-timeline .horizontal .square.up::after {margin: 12px 0px 0px 10px;border-left: 20px solid transparent;border-right: 20px solid transparent;border-top: 20px solid rgb(255, 255, 255); position: absolute; bottom: -15px;}
#history-timeline .horizontal .square.down{border-bottom: 2px solid #FF7700;}
#history-timeline .horizontal .square.down::after {margin: 0px 0 15px 15px;border-left: 20px solid transparent;border-right: 20px solid transparent;border-bottom: 20px solid rgb(255, 255, 255);position: absolute; top: -15px;}

/* Our Vision */
#our-vision{padding: 0;}
#our-vision .left-content-panel{padding: 70px;text-align: center;}
#our-vision .left-content-panel h3{font-size: 20px; text-transform: uppercase;font-weight: 700;line-height: 60px;border-bottom: 3px solid #144677;display: inline-block;position: relative;margin-bottom: 100px;}
#our-vision .left-content-panel h3::before{content: ''; position: absolute; left: 0; right: 0; bottom: -3px; width: 70px; height: 3px; background-color: #A6A6A6;margin: 0 auto;}
#our-vision .left-content-panel h4{font-size: 20px; text-transform: uppercase;font-weight: 700;margin-bottom: 15px;}
#our-vision .left-content-panel p{font-size: 18px; line-height: 34px; margin-top: 40px;}
#our-vision .mission{padding: 100px 100px 100px 200px; position: relative;}
#our-vision .mission-title-circle{display: inline-block;font-size: 20px; color: #fff; text-transform: uppercase; font-weight: 700; background-color: #144677; border-radius: 100%; height: 150px; width: 150px;text-align: center; line-height: 150px; border: 3px solid #fff; position: absolute; top: 35%; left: -75px;}
#our-vision .mission .list-group{background-color: transparent;}
#our-vision .mission .list-group .list-group-item{background-color: transparent;border: 0;color: #fff;display: flex;align-items: flex-start;margin-bottom: 30px;font-size: 18px;
line-height: 28px;}
#our-vision .mission .list-group .list-group-item img{height: 35px;margin-right: 30px;margin-top: 10px;}

/* Our Mission */
#our-mission{padding: 70px 0;}



/*--------------------------------------------*/
/*Management - Board Of Commissioners*/
/*--------------------------------------------*/
#management-banner{position: relative;}
#management-banner .content-panel{position: absolute; top: 0;margin-top: 210px; }
#management-banner .content-panel h6{font-size: 16px; text-transform: uppercase; color: #FF7700;font-weight: 700;}
#management-banner .content-panel h2{font-size: 50px;font-weight: 700; color: #ffff;}
#management-banner .content-panel p{font-size: 17px; color: #fff; line-height: 30px;}
#management-banner img{height: 550px;object-fit: cover;width: 100%;}

/* Member of board */
#member_of_board{padding-top: 100px;}
#member_of_board img{height: 500px;position: absolute; bottom: 0; left: 0; right: 0;margin: 0 auto;}
#member_of_board .content-panel{margin-top: 70px; padding-bottom: 50px;}
#member_of_board .content-panel h5{color: #003751; font-size: 24px;font-weight: 700;padding-top: 110px;position: relative;}
#member_of_board .content-panel h5::before{content: ''; position: absolute; top: 0; width: 30px; height: 100px; background-color: #FF7700;}
#member_of_board .content-panel h6{font-size: 17px; color: #010101; font-weight: 600;margin-bottom: 20px;}
#member_of_board .content-panel p{font-size: 16px; line-height: 26px; margin-bottom: 10px;}
#member_of_board.gray{background-color:#f4f4f4;}

/*--------------------------------------------*/
/*Customers*/
/*--------------------------------------------*/
#customers{padding: 70px 0;}
#customers .logos-list{display: block;}
#customers .logos-list li{border: 0; width: 20%; padding: 0 10px;float: left; margin-bottom: 20px;}
#customers .logos-list li span{border: 1px solid #51515126;display: block;padding: 0 30px;text-align: center; height: 150px;line-height: 150px;}

/*--------------------------------------------*/
/*Subsidiary*/
/*--------------------------------------------*/
#subsidiary{padding: 70px 0;}
#subsidiary .subsidiary-panel{border-left: 3px solid #FBC800;padding: 30px;box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);margin-bottom: 30px;}
#subsidiary .subsidiary-panel img{height: 30px;}
#subsidiary .subsidiary-panel h4{margin: 20px 0;font-size: 20px;font-weight: 600; text-transform: uppercase;}
#subsidiary .subsidiary-panel p{font-size: 15px;line-height: 22px; display: inline-block;}
#subsidiary .subsidiary-panel p strong{display: block;margin-bottom: 5px;font-size: 16px;}

/*--------------------------------------------*/
/*Subsidiary*/
/*--------------------------------------------*/
#certificate{padding: 70px 0;}
#certificate table tr td, #certificate table tr th{padding: 30px 0;border-bottom: 2px solid #FF7700;}

/*--------------------------------------------*/
/*Subsidiary*/
/*--------------------------------------------*/
#partner-logo{padding: 70px 0;}
#partner-logo .logos-list{display: block;}
#partner-logo .logos-list li{width: 33.3%; padding: 0px;float: left;border-left: 1px solid #51515126 !important; border-bottom: 1px solid #51515126 !important;padding: 0 30px;text-align: center;height: 200px;line-height: 200px;border: 0;}
#partner-logo .logos-list li img{height: 60px;}
#partner-logo .logos-list li:nth-child(1){border-left: 0 !important;}
#partner-logo .logos-list li:nth-child(4){border-left: 0 !important;}
#partner-logo .logos-list li:nth-child(7){border-left: 0 !important; border-bottom: 0 !important;}
#partner-logo .logos-list li:nth-child(8){border-bottom: 0 !important;}
#partner-logo .logos-list li:nth-child(9){border-bottom: 0 !important;}

#gallery{padding: 70px 0;}
#gallery .sc-eqUAAy img{height: 300px;object-fit: cover; width: 100%; margin-bottom: 30px;}

/*--------------------------------------------*/
/*News Updates*/
/*--------------------------------------------*/
#news_updates{padding: 70px 0;}
#news_updates .card{border: 0;margin-bottom: 30px;}
#news_updates .card .card-img-top{border-radius: 10px; object-fit: cover; height: 420px;}
#news_updates .card .card-body{padding: 30px 0;}
#news_updates .card .card-body .card-text{font-size: 14px; color: #6a6a6a; line-height: normal;}
#news_updates .card .card-body .card-title{font-size: 18px; line-height: 26px; margin-bottom: 30px;}
#news_updates .card .card-body .btn-link{font-size: 15px; color: #000;text-decoration: none;text-transform: uppercase;}
#news_updates .card .card-body .btn-link:hover{color: #f18221; }

/*--------------------------------------------*/
/*Contact Information*/
/*--------------------------------------------*/
#contact_info{padding: 40px 30px;margin-top: -60px;background-color: #fff;position: relative;box-shadow: 0 10px 40px rgba(0,0,0,0.1);}
#contact_info i{color: #FF7700;font-size: 24px;margin-right: 10px;}
#contact_info .content-panel h3{font-size: 20px; font-weight: 600;}
#contact_info .content-panel p{font-size: 14px;line-height: 22px;margin-bottom: 10px;}
#contact_info .content-panel p a{color: #1a1a1a;}
#contact_info .ps-5{border-left: 1px solid #d5d5d5;}

/* Get in Touch */
#contact_form{padding: 70px 0;}
#contact_form h2{font-size: 34px;font-weight: 700;text-align: center;line-height: 28px;margin-bottom: 30px;}
#contact_form p{font-size: 18px;text-align: center;}
#contact_form .form-control{height: 50px;}
#contact_form .submit-btn{height: 60px; background-color: #EB8D2E;font-size: 18px;font-weight: 600;width: 100%;border: 0; letter-spacing: 0.6px;}

/*--------------------------------------------*/
/*GCG*/
/*--------------------------------------------*/
#gcg{position: relative;}
#gcg .content-panel{position: absolute; top: 45%;}
#gcg .content-panel h6{font-size: 16px; text-transform: uppercase; color: #FF7700;font-weight: 700;}
#gcg .content-panel h2{font-size: 50px;font-weight: 700; color: #fff;}
#gcg img{height: 550px;object-fit: cover;width: 100%;}

/* General Shareholder Meeting */
#gsm{padding: 100px 0;}
#gsm .content-panel{margin-top: 50px;}
#gsm .content-panel h3{font-size: 34px;font-weight: 700; color: #144778;}
#gsm .content-panel p{font-size: 17px; line-height: 30px;}
#gsm .btn-download{height: 120px; width: 120px; border-radius: 100%;background-color: #39C83B;display: inline-block;color: #fff;text-align: center;padding-top: 30px;font-size: 14px;position: absolute;bottom: 10%; left: 40%;border: 5px solid rgba(255, 255, 255, 0.4);-moz-background-clip: padding; -webkit-background-clip: padding; background-clip: padding-box;}
#gsm .btn-download i{display: block;font-size: 24px;}

/*--------------------------------------------*/
/*Board of Commissioner*/
/*--------------------------------------------*/
#board-commissioner{padding-top: 100px;}
#board-commissioner .content-panel h3{text-align: center;font-size: 40px; font-weight: 700;margin-bottom: 20px;}
#board-commissioner .content-panel h3 span{color: #FF7700;}
#board-commissioner .content-panel p{text-align: center;color: #515151;font-size: 17px; line-height: 30px;margin-bottom: 70px;}

#term-office{background: #f4f4f4;padding: 70px 0;}
#term-office .content-panel h2{font-size: 40px; font-weight: 700;margin-bottom: 20px;margin-top: 70px;}
#term-office .content-panel p{font-size: 17px; line-height: 30px;color: #515151;}
#term-office .btn-download{height: 50px;background-color: #E9A933;color: #000000;display: inline-block;border-radius: 30px; line-height: 50px;padding: 0 20px;font-weight: 600;margin-top: 30px;margin-bottom: 30px;}
#term-office .btn-download i{font-size: 20px;}

/*--------------------------------------------*/
/*Board Of Director*/
/*--------------------------------------------*/
#directors-download-file{position: relative;margin-bottom: 70px;}
#directors-download-file img{height: 450px; width: 100%;object-fit: cover;}
#directors-download-file .btn-download{height: 120px; width: 120px; line-height: 110px; border-radius: 100%;background-color: #fff;display: inline-block;color: #f18221;text-align: center;position: absolute;top: 40%; left: 0%; right: 0; border: 5px solid rgba(255, 255, 255, 0.4);-moz-background-clip: padding; -webkit-background-clip: padding; background-clip: padding-box; margin: 0 auto;}
#directors-download-file .btn-download i{display: block;font-size: 40px;}
#directors-download-file .btn-download:hover{background-color: #f18221; color: #ffff;border: 5px solid rgba(141, 130, 33, 0.4);}

/*--------------------------------------------*/
/*GCG Structure*/
/*--------------------------------------------*/
#gcg_structure{padding: 100px 0;}
#gcg_structure img{height: 600px;object-fit: cover; width: 100%;}
#gcg_structure .content-panel{background-color: #fff;margin-left: -250px;position: relative;z-index: 1; padding: 50px 40px;margin-top: 100px;}
#gcg_structure .content-panel p{font-size: 17px; line-height: 30px;color: #515151;margin-bottom: 0;}
#gcg_structure .content-panel .btn-readmore{font-size: 16px; font-weight: 600; color: #f18221;text-align: center;line-height: 50px;margin-top: 30px;display: inline-block;}
#gcg_structure .content-panel .btn-readmore i{height: 50px; width: 50px;border-radius: 100%; background-color: #fce6d3;display: inline-block;margin-right: 10px;color: #010101;font-size: 18px;}
#gcg_structure .content-panel .btn-readmore:hover i{background-color: #f18221;}

/*--------------------------------------------*/
/*Corporation Secretary*/
/*--------------------------------------------*/
#corporation-secretary{padding: 100px 0;position: relative;}
#corporation-secretary img{border-radius: 100%; height: 500px; width: 500px; object-fit: cover;}
#corporation-secretary .btn-download {height: 120px;width: 120px;line-height: 110px;border-radius: 100%;background-color: #f18221;display: inline-block;color: #fff;text-align: center;position: absolute;bottom: 0%;right: 20%;border: 5px solid rgba(141, 130, 33, 0.2);-moz-background-clip: padding;-webkit-background-clip: padding;	background-clip: padding-box;margin: 0 auto; padding: 0;}
#corporation-secretary .btn-download i {display: block;font-size: 40px;}
#corporation-secretary .btn-download:hover {background-color: #f18221;	color: #ffff;border: 5px solid rgba(141, 130, 33, 0.4);}
#corporation-secretary .dropdown .dropdown-menu {border: 0;box-shadow: 0 7px 20px rgba(0,0,0,0.2);}
#corporation-secretary .content-panel{margin-top: 40px;background-color: #fff;}
#corporation-secretary .content-panel p{font-size: 17px; line-height: 30px;color: #515151;margin-bottom: 0;}
#corporation-secretary .content-panel h6{font-size: 18px; font-weight: 700;margin-top: 25px;}
#corporation-secretary .table td{line-height: 30px;border: 0;}
#corporation-secretary .table th{border: 0;line-height: 30px;}
#corporation-secretary .table th i{color: #FFAA17;margin-right: 5px;}
#corporation-secretary .content-list{margin-bottom: 20px;}
#corporation-secretary .content-list .list-group-item{border: 0;padding-left: 0;line-height: 22px;}
#corporation-secretary .content-list .list-group-item .list-group{margin: 10px 0;}
#corporation-secretary .content-list .list-group-item .list-group .list-group-item{padding-left: 15px;}
#corporation-secretary .cover-img{position: absolute; left: 0; top: 0;border-radius: 0;width: 100%;z-index: -1; }

/*--------------------------------------------*/
/*Committees*/
/*--------------------------------------------*/
#committees-info{padding-top: 100px;}
#committees-info .content-panel{text-align: center;}
#committees-info .content-panel h3 {text-align: center;font-size: 40px;	font-weight: 700;margin-bottom: 20px;}
#committees-info .content-panel h3 span {color: #FF7700;}
#committees-info .content-panel p {text-align: center;color: #515151;font-size: 17px;line-height: 30px;margin-bottom: 70px;}
#committees-info .btn-download{height: 200px; width: 200px; display: inline-block;text-align: center;border: 1px solid #C2C2C2; border-radius: 100%; color: #000000;font-size: 18px; padding-top: 60px;margin: 0 auto;color: #FF7700;}
#committees-info .btn-download i{display: block;font-size: 30px; margin-bottom: 10px; }

#committees .commissioner-card{text-align: center;position: relative;}
#committees .content-panel{color: #515151;font-size: 17px;line-height: 30px;}
#committees .commissioner-card::after{content: ''; width: 100%; height: 82%; border: 1px solid #D7D7D7; position: absolute; right: -20px; top: -20px;z-index: -1;}
#committees .commissioner-card h4{font-size: 22px;font-weight: 700;margin-top: 20px;}
#committees .commissioner-card h5{font-size: 18px;color: #FF7700;}
#committees .mt-6{margin-top: 150px;}

#responsibilities{padding: 80px;}
#responsibilities .content-panel h6{font-weight: 600;font-size: 20px;}
#responsibilities .content-panel p{line-height: 26px;}

/*--------------------------------------------*/
/*Internal Audits*/
/*--------------------------------------------*/
#internal-audits-info{padding-top: 100px;padding-bottom: 50px;}
#internal-audits-info .content-panel{text-align: center;}
#internal-audits-info .content-panel h3 {text-align: center;font-size: 40px;font-weight: 700;margin-bottom: 20px;}
#internal-audits-info .content-panel h3 span {color: #FF7700;}
#internal-audits-info .content-panel p {text-align: center;color: #515151;font-size: 17px;line-height: 30px;margin-bottom: 70px;}
#internal-audits-info .down-btn{position: relative;text-align: center;}
#internal-audits-info .down-btn .btn-download{height: 200px; width: 200px; display: inline-block;text-align: center;border: 1px solid #C2C2C2; border-radius: 100%; font-size: 18px;margin: 0 auto;color: #FF7700; background-color: #fff;}
#internal-audits-info .down-btn .btn-download i{display: block;font-size: 30px; margin-bottom: 10px; }
#internal-audits-info .down-btn .btn-download::before{content: ''; position: absolute; left: 0; top: 50%;width: 100%;height: 1px; background-color: #C2C2C2;z-index: -1;}
#internal-audits-info .down-btn .dropdown-menu{border: 0; box-shadow: 0 7px 20px rgba(0,0,0,0.2);}

#quote_panel{position: relative;}
#quote_panel h1{font-size: 54px; color: #fff;position: absolute; left: 0; right: 0; top: 0;z-index: 1;font-weight: 700;margin-top: 150px;text-align: center;}
#quote_panel h1 span{color: #FF7700;}
#quote_panel img{height: 420px; object-fit: cover;width: 100%;}

#gsm .content-box{padding: 30px;text-align: center; background-color: #fff; position: absolute;top: 55%;left: 50px;right: 50px;box-shadow: 0 7px 20px rgba(0, 0, 0, 0.2);}
#gsm .content-box h5{font-weight: 600;}
#gsm .content-box p{margin-bottom: 0; line-height: 24px; color: #515151;}

/*--------------------------------------------*/
/*Start Responsive and Mobile Views*/
/*--------------------------------------------*/

/*--------------------------------------------*/
/* Laptop with Desktop (1600px) View*/
/*--------------------------------------------*/
@media all and (max-width:1600px){
#header .navbar-expand-xl .navbar-nav .nav-link{margin: 0 10px;font-size: 14px;}
.home-slider .swiper-slide{height: 800px;}
}


/*--------------------------------------------*/
/* Laptop with Desktop (1400px) View*/
/*--------------------------------------------*/
@media all and (max-width:1400px){
.offcanvas{background-color: #000;}
.offcanvas .offcanvas-header .btn-close{color: #fff;}
.offcanvas .offcanvas-body .navbar-nav .nav-link{color: #fff;text-transform: uppercase;}
.offcanvas .offcanvas-body .navbar-nav .dropdown-menu{border: 0;}
#header .navbar .navbar-toggler{border: 0;float: right;}
#header .navbar .navbar-toggler:focus{box-shadow: none;}
.offcanvas .offcanvas-body .navbar-nav .nav-link .form-select{background-color: #303030;border: 0;color: #fff;}
#header .navbar-expand-xxl .navbar-nav .nav-link{font-size: 14px;margin: 0 10px;}
.offcanvas .offcanvas-body .navbar-nav .nav-item .dropdown-menu .nav-item .nav-link{color: #010101;padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);}
.offcanvas .offcanvas-body .navbar-nav .nav-item .dropdown-menu .nav-item .dropdown-menu{padding: 0 15px;}

.home-slider .swiper-slide{height: 700px;}
.home-slider .swiper-slide.slider-1 .slider-hero{width: 100%;}
.home-slider .swiper-slide.slider-1 h1{margin-top: 80px;font-size: 60px;}
.home-slider .swiper-slide.slider-2 h1{margin-top: 20%;font-size: 94px;}
.home-slider .swiper-slide.slider-3 h1{margin-top: 15%;font-size: 54px;}
.home-slider .swiper-slide.slider-3 h6{font-size: 20px;}
#quality_assurance .bg-img{height: 450px;}
#quality_assurance .content-panel{right: 70px;top: 80px;}
.home-slider .swiper-slide.slider-1 h1{font-size: 50px;line-height: 60px;}
.home-slider .swiper-slide.slider-1 h6{font-size: 18px;}
#about_us .content-panel h2{font-size: 34px;}
#testimonials h2{font-size: 34px;}
#news-updates h2{font-size: 34px;line-height: 44px;}

#who-we-are{padding-top: 220px;}
#who-we-are .light-lamp{height: 250px;}
#committees .commissioner-card::after{height: 84%;}

#our-vision .left-content-panel {padding: 60px;}
}

/*--------------------------------------------*/
/* Laptop with Tab (1199px) View*/
/*--------------------------------------------*/
@media all and (max-width:1199px){
.home-slider .swiper-slide.slider-1 h1{font-size: 50px;line-height: 60px;}
.home-slider .swiper-slide.slider-1 h6{font-size: 18px;}
.home-slider .swiper-slide.slider-1 h4{font-size: 18px;}
.home-slider .swiper-slide.slider-1 .slider-hero{width: 100%;}
#customers .logos-list li{width: 25%;}

}


/*--------------------------------------------*/
/* Laptop with Tab (1024px) View*/
/*--------------------------------------------*/
@media all and (max-width:1024px){


}

/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 991px){
#header{padding: 15px 0px;}
#header .head-logo{height: 50px;}
#management-banner .content-panel h2 {font-size: 40px;}
#about-us .content-panel h2{font-size: 34px;}
#our-vision{padding: 0;}
#our-vision .content-panel h3{margin-top: 30px;}
#our-vision .content-panel img {float: none;}
#our-vision .content-panel p{margin-bottom: 30px;}
#customers .logos-list li{width: 33.3%;}

#contact_info{padding: 30px 20px;}
#contact_info .ps-5{padding-left: 15px !important;border: 0;}

#gcg_structure {padding-bottom: 0px;}
#gcg_structure .content-panel{margin-left: 0px;padding: 0;margin-top: 50px;}

#committees .commissioner-card::after {height: 82%;}
#committees .mt-6 {margin-top: 50px;}
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (767px) View*/
/*--------------------------------------------*/
@media all and (max-width:767px){
.home-slider .swiper-slide{padding-top: 100px;}
#about_us .content-panel h2{font-size: 34px; line-height: 40px;}
.home-slider .swiper-slide.slider-1 h1 {font-size: 40px;line-height: 50px; margin-bottom: 20px;text-align: center;}
.home-slider .swiper-slide.slider-1 .slider-hero{display: none; }
.home-slider .swiper-slide.slider-1 h6{margin-bottom: 20px;text-align: center;}
.home-slider .swiper-slide.slider-1 h4{text-align: center;}
.home-slider .swiper-slide.slider-2 h1{font-size: 60px; line-height: 60px;}
.home-slider .swiper-slide.slider-3 h1{font-size: 44px; line-height: 50px;}
#testimonials h2{font-size: 34px;}
#news-updates h2{font-size: 34px;line-height: 40px;}
#news-updates .news-updates {margin-top: 0px;}
#news-updates .news-updates .swiper-slide .card .card-img-top{height: 380px;}

#Product img {height: 450px;}
#Product h2 {font-size: 40px;top: 35%;}

#product_services .content-panel h3{font-size: 30px;line-height: 40px;}
#product_services {padding: 50px 0;}
#quality_assurance .bg-img{height: 350px;}
#quality_assurance .content-panel{right: 0px;top: 0px;height: 350px;padding: 30px;}
#quality_assurance .content-panel h2{font-size: 30px;line-height: 34px;}

#who-we-are .light-lamp {height: 200px;}

#member_of_board img{position: relative;}
#member_of_board .content-panel{margin-top: 0;}
#member_of_board img{height: 380px;}

#our-mission .content-panel h3{position: relative;top: 0;}
#our-mission .content-panel img{left: 0; width: 100%;}
#our-vision .left-content-panel {padding: 20px;}
#our-vision .left-content-panel h3{margin-bottom: 70px;}
#our-vision .mission {padding: 50px 20px 50px 20px;}
#our-vision .mission-title-circle{position: relative;left: 0px;top: 0; right: 0; margin: 0 auto;display: block;}
#our-vision .mission .list-group .list-group-item{font-size: 16px;line-height: 26px;padding: 0;}
#our-vision .mission .list-group{margin-top: 20px;}

#about-us img{height: 450px;}
#management-banner img{height: 450px;}
#management-banner .content-panel{margin-top: 120px;}
#management-banner .content-panel h2 {font-size: 34px;}

#board-commissioner .content-panel h3{font-size: 28px;}
#term-office .content-panel h2{font-size: 28px;}
#term-office .content-panel h2{margin-top: 0px;}

#corporation-secretary img {height: 380px;width: 380px;}
#corporation-secretary .btn-download{bottom: -3%;right: 10%;}
#gcg .content-panel h2{font-size: 40px;}

#committees .commissioner-card{margin-bottom: 50px;}
#responsibilities {padding: 50px 0px;}
#responsibilities .content-panel {padding: 50px 0px;}

#quote_panel h1{font-size: 40px;}
}


/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (667px) View*/
/*--------------------------------------------*/
@media all and (max-width: 667px){
#about_us .content-panel h2 {font-size: 30px;line-height: 34px;	}
#customers .logos-list li{width: 50%;}
#customers .logos-list li span{padding: 0 10px;}
#partner-logo .logos-list li{width: 50%;}
#partner-logo .logos-list li:nth-child(1){border-left: 0 !important;}
#partner-logo .logos-list li:nth-child(3){border-left: 0 !important;}
#partner-logo .logos-list li:nth-child(4){border-left: 1px solid #51515126 !important;}
#partner-logo .logos-list li:nth-child(5){border-left: 0 !important;}
#partner-logo .logos-list li:nth-child(7){border-bottom: 1px solid #51515126 !important;}
#partner-logo .logos-list li:nth-child(8){border-bottom: 1px solid #51515126 !important;}
#partner-logo .logos-list li:nth-child(9){border-bottom: 0 !important;border-left: 0 !important;}

#gcg_structure{padding-top: 30px;}
#gcg_structure img{height: auto;}

#corporation-secretary img {height: 350px;width: 350px;}
}


/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (575px) View*/
/*--------------------------------------------*/
@media all and (max-width: 575px){

    
}